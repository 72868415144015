import * as React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';
import { repositoryConfigs } from '@prismic/utils/repositoryConfigs';
import linkResolver from '@prismic/utils/linkResolver';
import Link from '@components/Link/Link';
import ExternalLink from '@components/ExternalLink/ExternalLink';
import htmlSerializer from '@prismic/utils/htmlSerializer';
import Cookies from 'js-cookie';

import 'sanitize.css';
import './src/scss/styles.module.scss';
import 'react-image-lightbox/style.css';
import 'keen-slider/keen-slider.min.css';

export const onRouteUpdate = ({ location }) => {
  // Plausible Analytics
  if (process.env.NODE_ENV === `production` && typeof window.plausible !== `undefined`) {
    window.plausible(`pageview`);
  }

  // Special case for pages that want smooth scroll
  if (typeof document === 'object') {
    if (['/academy/marketplace-video-course/', '/features/'].includes(location.pathname)) {
      document.documentElement.style.scrollBehavior = 'smooth';
    } else {
      document.documentElement.style.scrollBehavior = 'auto';
    }
  }

  scrollToAnchor(location, 100);
};

export const onInitialClientRender = () => {
  const urlParams = new URLSearchParams(window.location.search);

  // Check if a rfsn value exists in the URL
  if (urlParams.has('rfsn')) {
    const paramValue = urlParams.get('rfsn');

    // Store the rfsn value in localStorage
    localStorage.setItem('rfsn', paramValue);
  }

  // If a user opens a link in navigation in a new tab, referrer is set as the previous page they were viewing. This avoids storing any
  // www.sharetribe.com pages as referrer
  const isReferrerFromSharetribe = document.referrer.includes('www.sharetribe.com');

  // Store the referrer URL in localStorage if it doesn't already exist
  if (
    !localStorage.getItem('referrer') &&
    document.referrer.length > 0 &&
    !isReferrerFromSharetribe
  ) {
    localStorage.setItem('referrer', document.referrer);
  }

  // Store first page visited
  if (!isReferrerFromSharetribe && !localStorage.getItem('first_page_visited')) {
    localStorage.setItem('first_page_visited', window.location.pathname);
  }

  // Store partner stack IDs
  if (urlParams.has('ps_xid')) {
    // Only proceed if a partner ID cookie doesn't already exist
    if (!Cookies.get('ps_partner_key')) {
      Cookies.set('ps_xid', urlParams.get('ps_xid'), {
        expires: 90,
        secure: true,
      });

      Cookies.set('ps_partner_key', urlParams.get('ps_partner_key'), {
        expires: 90,
        secure: true,
      });
    }

    // -- first touch attribution
    // in case the partner ID param and the cookie are the same
    // this means that the customer has clicked on a new affiliate link so we need to overwrite ps_xid with a new value
    if (Cookies.get('ps_partner_key') === urlParams.get('ps_partner_key')) {
      Cookies.set('ps_xid', urlParams.get('ps_xid'), {
        expires: 90,
        secure: true,
      });
    }
  }
};

function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`[data-id="${location.hash}"]`)?.offsetTop;

    if (item) {
      window.scrollTo({
        top: item - mainNavHeight,
        behavior: 'smooth',
      });
    }
  }

  return true;
}

// We wrap our app with the PrismicPreviewProvider in order to save our data to the global store
export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={Link}
    externalComponent={ExternalLink}
    richTextComponents={htmlSerializer}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
);
